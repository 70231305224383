<template>
  <div class="bg-transparent my-3">
    <FeedFilters @onFilter="onFilter" />

    <div class="d-flex align-items-center mb-3 justify-content-end">
      <ion-icon class="mr-1 sort-icon" :icon="filterOutline" />
      <multiselect
        v-model="selectedSort"
        class="choose-options sort-filter-select mr-2"
        placeholder="Sort Content"
        :options="sortOptions"
        select-label=""
        selected-label=""
        :disabled="loader || ['Following'].includes(tag)"
        :loading="loader"
        deselect-label=""
        :multiple="false"
        :taggable="false"
        open-direction="below"
        :close-on-select="true"
        :searchable="false"
        track-by="key"
        label="value"
        :allow-empty="false"
        :class="{ 'w-100': isMobSmallScreen }"
      >
        <template #noOptions></template>
      </multiselect>

      <ion-icon class="mr-1 sort-icon" :icon="optionsOutline" />
      <multiselect
        v-model="selectedContentFilter"
        class="choose-options"
        placeholder="Filter"
        :options="contentFilterOptions"
        select-label=""
        selected-label=""
        :disabled="loader"
        :loading="loader"
        deselect-label=""
        :multiple="false"
        :taggable="false"
        open-direction="below"
        :close-on-select="true"
        :searchable="false"
        track-by="key"
        label="value"
        :class="{ 'w-100': isMobSmallScreen }"
      >
        <template #noOptions></template>
      </multiselect>
    </div>

    <ion-button
      v-if="isMobSmallScreen"
      class="create-btn ml-auto mb-3 d-flex justify-content-end"
      @click="toggleCreate"
    >
      <div class="d-flex align-items-center justify-content-center">
        <inline-svg style="height: 18px" src="/icons/plus-circle.svg" /> Create
      </div>
    </ion-button>

    <TopContributors :tag="tag" />
    <div class="container">
      <div class="item col-md-3 col-sm-4 px-1">
        <div v-for="container in masonryContainers" :key="container.id" :style="container.style">
          <div :id="container.id"></div>
        </div>
      </div>
    </div>
    <MasonryFeed
      class="mb-5"
      :feed="feeds"
      :loader="loader"
      :total-feed="totalFeed"
      @loadMore="loadMore"
      @delete="deleteCurrGiftBox"
      @updated="updateFeeds"
      :lazyUserReaction="true"
      :reaction="reactions"
      :spaceroomcount="spaceroomcount"
      :blabrepliesCount="blabrepliesCount"
      :unreadCounts="unreadCounts"
      :commentsCount="commentsCount"
      :spaceMemberCount="spaceMemberCount"
      :chtrmStatusAndCount="chtrmStatusAndCount"
    />
  </div>
</template>

<script lang="ts" setup>
import MasonryFeed from './components/MasonryFeed.vue';
import FeedFilters from './components/FeedFilters.vue';
import TopContributors from './components/TopContributors.vue';
import { getFeedPage, getFeedPageV2 } from '@/shared/actions/follow';
import Multiselect from 'vue-multiselect';
import { filterOutline, optionsOutline } from 'ionicons/icons';
import { getReactedCharacters } from '@/shared/actions/characters';
import constants from '@/shared/statics/constants';
import { getSocialSpaceRoomsCount, getWorldsMemberCounts } from '@/shared/actions/worlds';
import { getCommentsCount } from '@/shared/actions/comments';
import { getChatroomCounts, getchatUnreadCounts } from '@/shared/actions/socialSpaceChatroom';
import { getCountofBlabReplies } from '@/shared/actions/blabs';
import { getCurrencyBoxStatus } from '@/shared/actions/currency';
import { featureFlags } from '@/shared/config/feature-flags';
import { uiStore } from '@/shared/pinia-store/ui';
import { nanoid } from 'nanoid';
import { authStore } from '@/shared/pinia-store/auth';

const props = defineProps({
  version: { type: Number, default: 0 },
});

const feedVersion = toRef(props, 'version');
const feeds: any = ref([]);
const page = ref(1);
const adsCount = ref(1);
const totalFeed = ref(false);
const loader = ref(false);
const clearFilters = ref(false);
const router = useRouter();
const currency = featureFlags.currency;

const { toggleCreate } = uiStore();
const masonryContainers = [
  { id: 'masonry-post', style: 'padding-left: 0.5rem;padding-right:0.5rem' },
  { id: 'masonry-fanwork', style: 'padding-left: 12px;padding-right: 12px' },
  { id: 'masonry-fanart', style: 'padding-left: 23px;padding-right: 23px' },
];

const masonryElements = ref<{ [key: string]: HTMLElement | null }>({
  'masonry-post': null,
  'masonry-fanwork': null,
  'masonry-fanart': null,
});

const tag: any = ref('All');
const selectedContentFilter: any = ref('');
const selectedSort: any = ref({ value: 'Recommended', key: '' });

const feedRouteName = 'home';
const reactions: any = ref({
  character: {},
  world: {},
  blab: {},
});
const spaceroomcount = ref({});
const blabrepliesCount = ref({});
const unreadCounts = ref({});
const commentsCount = ref({});
const spaceMemberCount = ref({});
const chtrmStatusAndCount = ref({});
const currStatus: any = ref({});
const contentFilterOptions: any = [
  { value: 'All Content', key: 'all' },
  { value: 'Posts', key: 'post' },
  { value: 'Characters', key: 'character' },
  { value: 'Worlds', key: 'world_story' },
  { value: 'Social Spaces', key: 'world_socialspace' },
  { value: 'Chatrooms', key: 'chatroom' },
  { value: 'Art', key: 'post_art' },
  { value: 'Art WIP', key: 'post_art_wip' },
  { value: 'Story', key: 'post_story' },
  { value: 'Comic', key: 'post_comic' },
];

const sortOptions: any = [
  { value: 'Recommended', key: '' },
  { value: 'Latest', key: 'latest' },
  { value: 'Trending', key: 'trending' },
  { value: 'Top', key: 'top' },
];

const { isPro } = authStore();
const { isLessThan570px } = useWindowSize();
const { ads: adsFlag } = featureFlags;

const windowWidth = computed(() => {
  const { width } = useWindowSize();
  return width.value;
});

const isMobSmallScreen = computed(() => {
  return windowWidth.value <= 576;
});

watch(selectedContentFilter, () => {
  if (!clearFilters.value) {
    if (selectedContentFilter.value && selectedContentFilter.value.key === 'all') {
      selectedContentFilter.value = '';
    } else {
      page.value = 1;
      feeds.value = [];
      getFeed(feedVersion.value);
    }
  }
});

watch(selectedSort, () => {
  page.value = 1;
  feeds.value = [];
  getFeed(feedVersion.value);
});

const onFilter = (tagName: any) => {
  tag.value = tagName;
  tag.value === 'All'
    ? router.push({ name: feedRouteName })
    : router.push({ name: feedRouteName, query: { filter: tag.value } });
  page.value = 1;
  feeds.value = [];

  selectedContentFilter.value = '';
  if (!selectedContentFilter.value) {
    clearFilters.value = true;
  }

  selectedSort.value = ['Following'].includes(tag.value)
    ? { value: 'Latest', key: 'latest' }
    : { value: 'Recommended', key: '' };
};

const fetchBulkReactionsForOtherTypes = async (type: string, ids: string[]) => {
  const resp = await getReactedCharacters(ids, type);
  return keyBy(resp, 'object_id');
};

const filterCharacters = (newFeeds: any[]) => {
  return newFeeds.filter(
    (item: any) =>
      item.type === constants.feedReactedTypes.CHARACTER ||
      (get(item, 'type')?.endsWith('follow') && get(item, 'entity.type') === constants.feedReactedTypes.CHARACTER)
  );
};

const filterTypeItems = (newFeeds: any[], type: string) => {
  return newFeeds.filter((item: any) => get(item, 'type') === type);
};

const filterPosts = (newFeeds: any[]) => {
  return newFeeds.filter(
    (item: any) =>
      item.type === constants.feedReactedTypes.POST ||
      (get(item, 'type')?.endsWith('follow') && get(item, 'entity.type') === 'blab')
  );
};

const fetchBulkReactions = async (type: string, filteredFeeds: any[]) => {
  let newData = {};

  if (filteredFeeds.length > 0 && !isEmpty(filteredFeeds)) {
    const reactionsData = await fetchBulkReactionsForOtherTypes(type, map(filteredFeeds, 'entity.id') as string[]);
    newData = keyBy(reactionsData, 'object_id');
  }
  reactions.value[type] = {
    ...reactions.value[type],
    ...newData,
  };
};

const fetchSocialSpaceRoomCounts = async (filteredFeeds: any[]) => {
  const newRoomCount = await getSocialSpaceRoomsCount(map(filteredFeeds, 'entity.id') as string[]);
  spaceroomcount.value = { ...spaceroomcount.value, ...keyBy(newRoomCount, 'world_id') };
};

const fetchSocialSpacesMemberCounts = async (filteredFeeds: any[]) => {
  const resp = await getWorldsMemberCounts(map(filteredFeeds, 'entity.id') as string[]);
  spaceMemberCount.value = { ...spaceMemberCount.value, ...keyBy(resp, 'world_id') };
};

const fetchCharsCommentsCount = async (filteredFeeds: any[]) => {
  const resp = await getCommentsCount(
    map(filteredFeeds, 'entity.id') as string[],
    constants.commentedAsTypes.CHARACTER
  );
  commentsCount.value = { ...commentsCount.value, ...keyBy(get(resp, 'counts', []), 'commented_on_object_id') };
};

const fetchChatroomCountsAndStatus = async (filteredFeeds: any[]) => {
  const resp = await getChatroomCounts(map(filteredFeeds, 'entity.id') as string[]);
  chtrmStatusAndCount.value = { ...chtrmStatusAndCount.value, ...keyBy(resp, 'id') };
};

const updateFeeds = () => {
  feeds.value = feeds.value.map((item: any) => {
    delete item.entity.showBox;
    delete item.entity.progressId;
    return item;
  });
};
const deleteCurrGiftBox = (id: any) => {
  const index = feeds.value.findIndex((item: any) => item.entity.id === id);

  if (index >= 0) {
    delete feeds.value[index].entity['showBox'];
    delete feeds.value[index].entity['progressId'];
  }
};
const fetchBlabRepliesCount = async (filteredFeeds: any[]) => {
  const resp = await getCountofBlabReplies(map(filteredFeeds, 'entity.id') as string[]);
  blabrepliesCount.value = { ...blabrepliesCount.value, ...keyBy(resp, 'blab') };
};
const fetchchatUnreadCounts = async (filteredFeeds: any[]) => {
  const resp = await getchatUnreadCounts(map(filteredFeeds, 'entity.id') as string[]);
  unreadCounts.value = { ...unreadCounts.value, ...keyBy(resp, 'room') };
};

const fetchLazyData = async (newFeeds: any[]) => {
  const characters = filterCharacters(newFeeds);
  const spaces = filterTypeItems(newFeeds, constants.feedReactedTypes.SOCIALSPACE);
  const worlds = filterTypeItems(newFeeds, constants.feedReactedTypes.STORY);
  const posts = filterPosts(newFeeds);
  const chatrooms = filterTypeItems(newFeeds, 'chatroom');

  fetchSocialSpaceRoomCounts(spaces);
  fetchSocialSpacesMemberCounts(spaces);
  fetchBlabRepliesCount(posts);
  fetchCharsCommentsCount(characters);
  fetchChatroomCountsAndStatus(chatrooms);
  fetchchatUnreadCounts(chatrooms);
  fetchBulkReactions(constants.feedReactedTypes.CHARACTER, characters);
  fetchBulkReactions(constants.feedReactedTypes.WORLD, worlds);
  fetchBulkReactions(constants.feedReactedTypes.BLAB, posts);
};

const fetchBoxesStatus = async () => {
  const resp1 = await getCurrencyBoxStatus();
  currStatus.value = resp1;
};

const getImageHeight = async (url: any, width: any) => {
  const img = new Image();
  img.src = url;
  return new Promise((resolve) => {
    img.onload = () => {
      const aspectRatio = img.naturalHeight / img.naturalWidth;

      resolve(aspectRatio * width);
    };
  });
};

const extractImageUrls = (description: any) => {
  const imgUrls = [];
  const rex = /<img[^>]+src="(https:\/\/[^">]+)"/g;
  let match;
  while ((match = rex.exec(description))) {
    imgUrls.push(match[1]);
  }
  return imgUrls;
};
const getContainerIdAndUrl = (item: any) => {
  if (get(item, 'type') === 'post' || get(item, 'entity.type') === 'blab') {
    return {
      containerId: 'masonry-post',
      url: extractImageUrls(item.entity.description)[0] || null,
    };
  } else if (get(item, 'type')?.endsWith('follow') && ['fanart_featured', 'fanart_submitted'].includes(item.action)) {
    const url = get(item, 'extra.fanart_url') || get(item, 'extra.fanwork_image');
    return {
      containerId: item.extra.fanart_url ? 'masonry-fanart' : 'masonry-fanwork',
      url,
    };
  }
  return { containerId: '', url: null };
};

const getFeed = async (version = 0) => {
  loader.value = true;
  let resp = null;
  if (!version) {
    resp = await getFeedPage({
      page: page.value,
      ...(tag.value && !['All', 'Following'].includes(tag.value) && { tag: tag.value }),
      ...(tag.value && ['Following'].includes(tag.value) && { following: 1 }),
      ...(selectedContentFilter.value &&
        selectedContentFilter.value.key && { filter: selectedContentFilter.value.key }),
      ...(selectedSort.value &&
        !['Following'].includes(tag.value) &&
        selectedSort.value.key && { order_by: selectedSort.value.key }),
    });
  } else if (version === 2) {
    resp = await getFeedPageV2({
      page: page.value,
      ...(tag.value && !['All', 'Following'].includes(tag.value) && { tag: tag.value }),
      ...(tag.value && ['Following'].includes(tag.value) && { following: 1 }),
      ...(selectedContentFilter.value &&
        selectedContentFilter.value.key && { filter: selectedContentFilter.value.key }),
      ...(selectedSort.value &&
        !['Following'].includes(tag.value) &&
        selectedSort.value.key && { order_by: selectedSort.value.key }),
    });
  }

  let uniqueRes = [...new Set(resp.results)] as any;
  if (currency && uniqueRes?.length) {
    await fetchBoxesStatus();
    if (currStatus.value.show_box) {
      const index = Math.floor(Math.random() * uniqueRes.length);
      uniqueRes[index] = {
        ...uniqueRes[index],
        entity: {
          ...uniqueRes[index].entity,
          showBox: true,
          progressId: currStatus.value.box_progress_id,
        },
      };
    }
  }

  if (uniqueRes?.length && adsFlag && !isPro.value) {
    let tempCount = adsCount.value;
    uniqueRes = uniqueRes.reduce((acc: any, item: any, index: any) => {
      acc.push(item);
      let val: any = feeds.value && feeds.value.length ? feeds.value.length - tempCount + (index + 1) : index + 1;
      let totalVal = isLessThan570px.value ? val % 6 : val % 10;
      if (totalVal === 0 && totalVal >= 0) {
        acc.push({ entity: { id: nanoid(8) }, type: 'ads' });
        adsCount.value += 1;
      }
      return acc;
    }, []);
  }

  if (uniqueRes.length) {
    masonryContainers.forEach((container) => {
      masonryElements.value[container.id] = document.getElementById(container.id);
    });
    const imageLoadPromises = uniqueRes.map(async (item: any) => {
      const { containerId, url } = getContainerIdAndUrl(item);

      if (containerId && url) {
        const container = masonryElements.value[containerId];
        if (container) {
          const height = await getImageHeight(url, container.clientWidth);
          return { ...item, height };
        }
      }

      return item;
    });

    uniqueRes = await Promise.all(imageLoadPromises);
  }

  const ids = feeds.value
    .filter((item: any) => item.type !== 'follow' || item.type !== 'superfollow')
    .map((feed: any) => feed.entity.id);
  const followIds = feeds.value
    .filter((item: any) => item.type === 'follow' || item.type === 'superfollow')
    .map((feed: any) => feed.id);

  feeds.value = feeds.value.concat(
    uniqueRes.filter(
      (res: any) =>
        (!ids.includes(res.entity.id) && (res.type !== 'follow' || res.type !== 'superfollow')) ||
        (!followIds.includes(res.entity.id) && (res.type === 'follow' || res.type === 'superfollow'))
    )
  );

  fetchLazyData(uniqueRes);

  loader.value = false;
  totalFeed.value = !!resp.next;

  clearFilters.value = false;
};

const loadMore = () => {
  page.value++;
  getFeed(feedVersion.value);
};

onMounted(async () => {
  // router.push({ name: feedRouteName })
  getFeed(feedVersion.value);
});
</script>

<style lang="sass" scoped>
.container
  margin: 0 auto

.item
  position: static
  max-width:33.333333%
  @media(max-width:1370px) and (min-width:1185px)
    max-width:50%
  @media(max-width:825px) and (min-width:540px)
    max-width:50% !important
  @media(max-width:540px)
    max-width:100% !important


.text
  font-weight: bold
  margin-left: 0.5rem
.feed
  font-size: 28px
  font-weight: bold

.create-btn
  --border-radius: 15px
  width: 120px
  --background:#9872fe

.choose-options
    width: 166px

.sort-icon
  font-size: 24px !important
  color: #7050B7
  @media(max-width:576px)
    font-size: 48px !important

.multiselect
  min-height: unset

:deep
  .multiselect--active .multiselect__select
    transform: rotateZ(0deg) !important
    border-bottom-right-radius: 0px !important

  .multiselect--active .multiselect__tags
    border-bottom-left-radius: 0px !important
    border-bottom-right-radius: 0px !important


:deep(.multiselect--disabled)
  background: unset !important


:deep(.multiselect)
  .multiselect__tags
    border-radius: 12px
    border: 3px solid  #7050B7
    height: 30px
    min-height: 30px !important
    padding: 3px 40px 0 8px

  .multiselect__content-wrapper
    border-radius: 15px !important
    border-top-left-radius: 0px !important
    border-top-right-radius: 0px !important

  .multiselect__select
    height: 29px !important
    background: #7050B7
    width: 35px !important
    border-top-right-radius: 15px
    border-bottom-right-radius: 15px
    line-height: 16px !important
    right: 0px
  .multiselect__select::before
    border-color: #fff transparent transparent transparent !important
  .multiselect__single
    line-height: 18px !important
    width: 120px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
  .multiselect__placeholder
    padding-top: 0px
  .multiselect__spinner
    height: 29px !important
    background:  #7050B7
    width: 35px !important
    border-top-right-radius: 15px
    border-bottom-right-radius: 15px
    line-height: 16px !important
  .multiselect__spinner:before, .multiselect__spinner:after
    border-color: #ffffff transparent transparent !important

:deep(.sort-filter-select)
  .multiselect__single
    font-size: 14px !important
  .multiselect__tags
    border: 3px solid #7050B7 !important

  .multiselect__select
    background: #7050B7 !important
  .multiselect__spinner
    background: #7050B7 !important
</style>
