<template>
  <div class="events w-100 position-relative">
    <div class="d-flex events-list w-100">
      <scroller
        :class="{ 'mx-2': loadingEvent }"
        ref="scrollerRef"
        :scroll-size="300"
        :threshold="20"
        class="w-100"
        :no-gradient-background="true"
      >
        <ion-skeleton-text v-if="loadingEvent" :animated="true" class="event-loader mt-2" />
        <div v-else class="events-card d-flex align-items-center">
          <ion-button class="see-all-text">
            <router-link v-if="seeAllLink" :to="seeAllLink" style="color: white">
              See all <span v-if="totalCount">{{ totalCount }}</span> current events
            </router-link>
          </ion-button>
          <div
            style="height: 145px"
            class="no-data d-flex justify-content-center align-items-center"
            v-if="isEmpty(visibleTasks)"
          >
            No Active Events!
          </div>
          <template v-for="(task, index) in sortedEvents" :key="index">
            <event-task-card
              :task="task"
              :open-character-roulette="openCharacterRoulette"
              :open-oc-decoration="openCharacterDecoration"
              :open-art-roulette="openArtRoulette"
              :per-day-sub-count="perDaySubCount"
              @select="getSelectedTask"
              @end="recalcTaskTimes"
            />
          </template>
        </div>
      </scroller>
    </div>

    <character-roulette-modal
      :is-open="isCharacterRouletteOpen"
      :close="closeCharacterRoulette"
      @dismiss-modal="closeCharacterRoulette"
    />
    <art-roulette-modal :is-open="isArtRouletteOpen" :close="closeArtRoulette" @dismiss-modal="closeArtRoulette" />
    <choose-char-decoration-modal
      :is-open="isChooseCharDecorationOpen"
      :task="selectedtask"
      :chars="chars"
      :total-chars="totalChars"
      :is-search="isSearchText"
      :loading="loading"
      @search="getSearch"
      @loadMore="loadMore"
      @close="closeCharDecorationModal"
      @dismiss-modal="closeCharDecorationModal"
      @action="closeAndOpenOcModal"
    />
    <oc-decoration-modal :is-open="isOpenDecorateCharModal" :task="selectedtask" @close="closeDecCharModal" />
    <loading-modal :is-open="isOpenLoadingModal" @close="closeLoadingModal" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import EventTaskCard from './EventTaskCard.vue';
import { SiteEventTask } from '@/shared/types/static-types';
import { convertIntoDays, dateHasPassed, formatFromNowInMilliseconds } from '@/shared/utils/dateTime';
import { ListLayout } from '@/shared/types/ui';
import CharacterRouletteModal from '@/shared/modals/CharacterRouletteModal.vue';
import ArtRouletteModal from '@/shared/modals/ArtRouletteModal.vue';
import ChooseCharDecorationModal from '@/shared/modals/ChooseCharDecorationModal.vue';
import LoadingModal from '@/shared/modals/LoadingModal.vue';
import OcDecorationModal from '@/shared/modals/OcDecorationModal.vue';
import {
  fetchDecorCharacters,
  fetchMyOcCharacters,
  fetchSubmissionStatus,
  getAllInPoolChar,
  getCharactersEventPollCounts,
} from '@/shared/actions/events';
import { onboardingStore } from '@/shared/pinia-store/onboarding';

@Options({
  name: 'HorizontalEventTasks',
  components: {
    EventTaskCard,
    CharacterRouletteModal,
    ArtRouletteModal,
    ChooseCharDecorationModal,
    OcDecorationModal,
    LoadingModal,
  },
})
export default class HorizontalEventTasks extends Vue {
  get = get;
  isEmpty = isEmpty;
  dateHasPassed = dateHasPassed;
  formatFromNowInMilliseconds = formatFromNowInMilliseconds;
  isCharacterRouletteOpen = false;
  isArtRouletteOpen = false;
  isChooseCharDecorationOpen = false;
  isOpenDecorateCharModal = false;
  ocDecorationOpen = false;
  public selectedtask: any = {};
  public subStatus: any = {};
  public chars = [];
  public isAllCharDisabled = false;
  public loading = true;
  public isSearchText = false;
  public decoration = [];
  public isOcCharsFetching = false;
  public page = 1;
  public totalChars = 0;
  public searchText = '';
  public isOpenLoadingModal = false;
  public perDaySubCount = 0;

  @Prop({ default: () => 'Current Events' }) public sectionTitle!: string;
  @Prop({ required: true }) public quests!: SiteEventTask[];
  @Prop({ required: false }) public seeAllLink!: {};
  @Prop({ default: 0 }) public totalCount!: {};
  @Prop() public loadingEvent!: Boolean;
  @Prop() public layout!: ListLayout;
  @Prop() public currentTab!: String;

  public get disabledForNewUser() {
    const { disabledForNewUser } = onboardingStore();
    return disabledForNewUser.value;
  }

  public get disabledRemainingMs() {
    const { disabledRemainingMs } = onboardingStore();
    return disabledRemainingMs.value;
  }

  public visibleTasks: SiteEventTask[] = [];
  $refs!: {
    scrollerRef: HTMLInputElement;
  };

  @Watch('currentTab', { deep: true })
  public recalcScroll() {
    setTimeout(() => {
      if (this.$refs.scrollerRef && this.currentTab === 'events') (this.$refs.scrollerRef as any).calculateScroll();
    }, 150);
  }

  public recalcTaskTimes(task?: SiteEventTask) {
    if (task && dateHasPassed(task.ends_at) && task.will_refresh)
      setTimeout(() => {
        this.$emit('refresh');
      }, 4000);
    this.visibleTasks = this.quests.filter(
      (q: SiteEventTask) =>
        (!q.ends_at || !dateHasPassed(q.ends_at)) && // ongoing clause 1
        (!q.starts_at || dateHasPassed(q.starts_at))
    ); // ongoing clause 2
    const upcomingTasks = this.quests.filter((q: SiteEventTask) => q.starts_at && !dateHasPassed(q.starts_at));
    this.visibleTasks = [...this.visibleTasks, ...upcomingTasks];
  }

  public getSelectedTask(task: any) {
    this.selectedtask = task;
  }

  public openCharacterRoulette() {
    this.isCharacterRouletteOpen = true;
  }

  public get getDays() {
    const { days } = convertIntoDays(this.selectedtask.ends_at);
    return days;
  }

  public async getSearch(text: any) {
    this.isSearchText = true;

    this.searchText = text;
    const resp = await fetchMyOcCharacters({ ...(!!text && { search: text }), page: 1, page_size: 40 });
    this.chars = resp.results;
    this.totalChars = resp.count;
    this.page = 1;

    this.isSearchText = false;
  }

  public get getHours() {
    const { hours } = convertIntoDays(this.selectedtask.ends_at);
    return hours;
  }

  public async fetchCounts() {
    const resp = await getCharactersEventPollCounts();
    this.perDaySubCount = get(resp, 'total per day submission');
  }

  public async openCharacterDecoration() {
    if (this.getDays >= 1) {
      await this.getOcChars();
    }
    if (!this.isOpenLoadingModal) {
      // this.isChooseCharDecorationOpen = true;

      if (this.isAllCharDisabled) {
        this.isOpenDecorateCharModal = true;
      } else if (this.subStatus.is_submitted && this.getDays >= 1) {
        this.isOpenDecorateCharModal = true;
      } else if (this.getDays < 1 && this.getHours <= 24) {
        this.isOpenDecorateCharModal = true;
      } else if (isEmpty(this.chars)) {
        this.isOpenDecorateCharModal = true;
      } else if (this.subStatus.is_submitted && !isEmpty(this.chars)) {
        this.isOpenDecorateCharModal = true;
      } else this.isChooseCharDecorationOpen = true;
    }
  }

  public get sortedEvents() {
    return orderBy(this.visibleTasks, [
      (task: any) => {
        return !task.is_new;
      },
      (task: any) => {
        return !dateHasPassed(task.starts_at);
      },
      (task: any) => {
        return (task.action_url || '').includes('?quest=oc') ? this.computeEndDate(task) : task.ends_at;
      },
    ]);
  }

  public computeEndDate(task: any) {
    const nowDate = new Date();

    const nowHours = nowDate.getHours();
    const nowMins = nowDate.getMinutes();

    const startDate = new Date(task.starts_at);
    const startHours = startDate.getHours();
    const startMins = startDate.getMinutes();

    let totalHours;

    if (nowHours < startHours) {
      totalHours = nowHours + (24 - startHours);
    } else {
      totalHours = nowHours - startHours;
    }

    const totalMinutes = nowMins - startMins + 60;

    const remainingHours = 24 - totalHours;
    const remainingMins = 60 - totalMinutes;

    nowDate.setHours(nowHours + remainingHours);
    nowDate.setMinutes(nowMins + remainingMins);

    return nowDate.toISOString();
  }

  public closeCharDecorationModal() {
    this.isChooseCharDecorationOpen = false;
    this.getSubStatus();
  }

  public closeAndOpenOcModal() {
    this.closeCharDecorationModal();
    if (!isEmpty(this.decoration)) this.isOpenDecorateCharModal = true;
  }

  public closeDecCharModal() {
    this.isOpenDecorateCharModal = false;
  }

  public closeLoadingModal() {
    this.isOpenLoadingModal = false;
  }

  public async getSubStatus() {
    const resp = await fetchSubmissionStatus();
    this.subStatus = resp;
  }

  public async getOcChars() {
    this.loading = true;
    this.isOcCharsFetching = true;
    this.isOpenLoadingModal = true;
    const resp = await fetchMyOcCharacters({ page: 1, page_size: 40 });
    this.chars = resp.results;
    this.totalChars = resp.count;
    this.loading = false;
    this.closeLoadingModal();
  }

  public async fetchAllInPoolChar() {
    const resp = await getAllInPoolChar();
    this.isAllCharDisabled = resp.all_in_pool;
  }

  public async loadMore() {
    this.page = this.page + 1;
    const resp = await fetchMyOcCharacters({
      page: this.page,
      page_size: 40,
      ...(this.searchText && { search: this.searchText }),
    });
    if (this.page !== 1) {
      this.chars = this.chars.concat(resp.results);
    } else {
      this.chars = resp.results;
    }
    this.totalChars = resp.count;
  }

  public async mounted() {
    await this.fetchAllInPoolChar();
    this.fetchCounts();
    this.getSubStatus();
    if (this.$route.query && this.$route.query.event === 'decoration-fest') {
      this.fetchselectedtask();
    }
  }

  public closeCharacterRoulette() {
    this.isCharacterRouletteOpen = false;
  }

  public openArtRoulette() {
    this.isArtRouletteOpen = true;
  }

  public closeArtRoulette() {
    this.isArtRouletteOpen = false;
  }

  @Watch('selectedtask')
  async fetchselectedtask() {
    const resp = await fetchDecorCharacters(this.selectedtask.id || this.$route.query.id);
    this.decoration = resp.results;
  }

  @Watch('quests')
  tasksChanged() {
    if (this.quests && this.quests.length) this.recalcTaskTimes();
    else this.visibleTasks = [];
  }
}
</script>
<style lang="sass" scoped>
.events-card
  gap: 5px
  margin-top: 1rem
  @media(max-width:570px)
    margin-bottom:0.5rem
.event-loader
  width: 100%
  height: 194px
.disabled-events
  background: rgba(209, 171, 245, 0.8)
  z-index: 1000
  width: 100%
  height: 100%
  color: rgb(103, 28, 164)
  font-size: 30px
  font-weight: bold
  .timer
    color: #FFF
.title
  font-size: 36px
  font-weight: bold
  margin-bottom: 12px
  margin-top: 30px
.finish-msg
  font-size: 18px
  font-weight: bold
.events
  overflow-x: auto
.events-list
  overflow-x: auto
.events-list::-webkit-scrollbar
  display: none
.events-list
  -ms-overflow-style: none
  scrollbar-width: none
.actions
  position: absolute
  bottom: 0
.see-all-text
  font-size: 16px
  height: 180px
  white-space: normal
  text-align: left
  width: 104px
  max-width: 104px
  min-width: 104px
  border-radius:12px
  background: #7050B7 !important
  --background: #7050B7 !important
  color: white
  text-decoration: underline

.mb-5
  color: #737373
.dark .see-all-text
  color: #ddd !important
</style>
