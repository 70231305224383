<template>
  <div>
    <scroller ref="scrollerRef" :scroll-size="300" :threshold="20" :no-gradient-background="true">
      <ion-skeleton-text v-if="loading" :animated="true" class="comp-bomb-field mt-2" />
      <div v-else>
        <div class="compliment-bombs-card d-flex align-items-center">
          <div class="d-flex flex-column">
            <ion-button
              fill="clear"
              :class="button.class"
              v-for="(button, index) in complimentButtons"
              :key="index"
              @click="action(button)"
            >
              <span :class="{ 'mx-1': button.isFeatured }">{{ button.title }}</span>
            </ion-button>
          </div>
          <compliment-bombs-card
            v-for="(character, index) in complimentBombChars"
            :key="index"
            :cb-daily-limit="cbDailyLimit"
            :inventory="inventory"
            :complimentCharacters="complimentCharacters"
            :character="character"
            :is-cb-loader="isCbLoader"
            v-show="!isEmpty(complimentBombChars)"
            @fetch="fetchCbLimit"
          />
        </div>

        <div v-if="isEmpty(complimentBombChars)" class="no-data py-5 text-black">No Characters Featured Yet!</div>
      </div>
    </scroller>

    <compliment-bombs-hub-modal
      :is-open="isOpenCompBombHubsModal"
      :inventory="inventory"
      :gift-inventory="giftInventory"
      @close="closeCbombHubModal"
      @updated="emits('updated')"
      @gifted="fetchInventoryDetails"
    />

    <compliment-bomb-use-and-purchase-modal
      :is-open="isOpenComplimentBombModal"
      :inventory="inventory"
      :giftInventory="giftInventory"
      @close="isOpenComplimentBombModal = false"
      @gifted="fetchInventoryDetails"
      @updated="emits('updated')"
    />
  </div>
</template>

<script lang="ts" setup>
import { sparklesOutline } from 'ionicons/icons';
import ComplimentBombsCard from '@/shared/components/ComplimentBombsCard.vue';
import { complimentBombsButton } from '@/shared/statics/constants';
import ComplimentBombsHubModal from '@/shared/modals/ComplimentBombsHubModal.vue';
import ComplimentBombUseAndPurchaseModal from '@/shared/modals/ComplimentBombUseAndPurchaseModal.vue';
const props = defineProps({
  complimentBombChars: { type: Array, default: () => [] },
  isCbLoader: { type: Boolean, default: false },
  inventory: { type: Object, default: () => {} },
  giftInventory: { type: Object, default: () => {} },
  complimentCharacters: { type: Array, default: () => [] },
  cbDailyLimit: { type: Object, default: () => {} },
  loading: { type: Boolean, default: false },
});

const router = useRouter();
const isOpenCompBombHubsModal = ref(false);
const isOpenComplimentBombModal = ref(false);
const complimentBombChars = toRef(props, 'complimentBombChars');
const scrollerRef: Ref = ref(null);
const complimentButtons: any = complimentBombsButton;
const inventory = toRef(props, 'inventory');
const giftInventory = toRef(props, 'giftInventory');
const tempInvType = ref('');
const emits: any = defineEmits(['updated', 'fetch', 'gifted']);
watch(
  complimentBombChars,
  () => {
    setTimeout(() => {
      if (scrollerRef.value) (scrollerRef.value as any).calculateScroll();
    }, 150);
  },
  { deep: true }
);

const action = (button: any) => {
  if (button.isFeatured) {
    isOpenComplimentBombModal.value = true;
  } else {
    router.replace({ name: 'home', query: { modal: 'compliment-bomb-hub' } });
    isOpenCompBombHubsModal.value = true;
  }
};

const fetchInventoryDetails = (val: any) => {
  tempInvType.value = val;
  emits('gifted', tempInvType.value);
};

const closeCbombHubModal = () => {
  isOpenCompBombHubsModal.value = false;
  router.push({ name: 'home' });
};
const fetchCbLimit = () => {
  emits('fetch');
};

onMounted(() => {
  if (router.currentRoute.value?.query?.modal) {
    isOpenCompBombHubsModal.value = true;
  }
});
</script>
<style lang="sass" scoped>
.buttons
  @media(max-width:375px)
    flex-wrap: wrap

.comp-bomb-field
  width: 100%
  height: 194px
.compliment-bombs-card
  gap: 5px
  margin-top: 1rem
  margin-bottom: 0.5rem
.icon
  font-size: 15px
.featured-char
  border-radius: 10px
  font-weight: bolder
  text-decoration: underline
  font-size: 14px
  white-space: break-spaces
  color: white
  height: 116px
  background: #7050B7
  text-align: left
  width: 104px
  min-width: 104px
  max-width: 104px
  @media(max-width:375px)
    text-decoration: none
.manage-bomb
  font-size: 12px
  height:55px
  color: #7050B7
  font-weight: bolder
  text-align: left
  text-decoration: underline
  border:2px solid #7050B7
  white-space: break-spaces
  border-radius:10px
  align-items: center
  width: 104px
  min-width: 104px
  max-width: 104px
  @media(max-width:375px)
    text-decoration: underline
</style>
