<template>
  <div class="fanart-card">
    <div class="tag d-flex align-items-center justify-content-center position-absolute">FanArt</div>
    <div
      v-if="
        ['fanart_featured'].includes(feed.action) && (get(feed, 'extra.fanart_url') || get(feed, 'extra.fanwork_image'))
      "
      class="d-flex flex-wrap align-items-center mt-4 mb-2"
      style="font-size: 15px"
    >
      <router-link
        :class="{
          'text-color-username': usernameColor(get(feed, 'user')) && !usernameColor(get(feed, 'user')).startsWith('#'),
          'bold-username': usernameColor(get(feed, 'user')),
        }"
        :style="
          !usernameColor(get(feed, 'user'))
            ? ''
            : usernameColor(get(feed, 'user')).startsWith('#')
            ? `color: ${usernameColor(get(feed, 'user'))} !important`
            : `background-image: ${usernameColor(get(feed, 'user'))} !important`
        "
        :to="{
          name: 'profile',
          params: { username: get(feed, 'user.username') },
        }"
        >{{ truncateText(get(feed, 'user.username'), 15) }}
      </router-link>
      <strong class="mx-1">
        {{ 'featured FanArt to' }}
      </strong>
      <div class="d-flex align-items-center">
        <img
          v-if="get(entity, 'info.cropProfilePicture')"
          v-image
          class="img-char mr-1"
          :src="resizeUpload(get(entity, 'info.cropProfilePicture', '/empty.png'), '80x80')"
        />
        <router-link
          v-if="entity"
          :to="{
            name: 'character-profile-new',
            params: { slug: get(entity, 'slug') },
          }"
          >{{ truncateText(get(entity, 'info.name'), 15) }}'s
        </router-link>
      </div>
      <strong class="ml-1"> profile </strong>
    </div>
    <div style="height: 100%" :class="['fanart_featured'].includes(feed.action) ? 'feature-card' : ''">
      <div
        v-if="get(feed, 'extra.fanart_url') || get(feed, 'extra.fanwork_image')"
        class="image d-flex align-items-center justify-content-center"
        :style="fanArtImgSize"
      >
        <img
          loading="lazy"
          :src="resizeUpload(get(feed, 'extra.fanart_url') || get(feed, 'extra.fanwork_image'), '250x250')"
        />
      </div>
      <div
        class="d-flex align-items-center my-2"
        :class="get(feed, 'extra.fanart_url') || get(feed, 'extra.fanwork_image') ? '' : 'mt-4'"
        style="font-size: 15px"
        @click.stop
      >
        <strong class="mr-2">
          {{ get(feed, 'extra.fanart_url') || get(feed, 'extra.fanwork_image') ? 'FanArt of' : 'Featuring' }}
        </strong>
        <img
          v-if="get(entity, 'info.cropProfilePicture')"
          v-image
          class="img-char mr-1"
          :src="resizeUpload(get(entity, 'info.cropProfilePicture', '/empty.png'), '80x80')"
        />
        <router-link
          v-if="entity"
          :to="{
            name: 'character-profile-new',
            params: { slug: get(entity, 'slug') },
          }"
          >{{ truncateText(get(entity, 'info.name'), 15) }}
        </router-link>
      </div>
      <div
        v-if="
          ['fanart_submitted'].includes(feed.action) &&
          !(get(feed, 'extra.fanart_url') && get(feed, 'extra.fanwork_image'))
        "
      >
        <strong> Character belongs to </strong>
        <router-link
          :class="{
            'text-color-username':
              usernameColor(get(feed, 'entity.author')) && !usernameColor(get(feed, 'entity.author')).startsWith('#'),
            'bold-username': usernameColor(get(feed, 'entity.author')),
          }"
          :style="
            !usernameColor(get(feed, 'entity.author'))
              ? ''
              : usernameColor(get(feed, 'entity.author')).startsWith('#')
              ? `color: ${usernameColor(get(feed, 'entity.author'))} !important`
              : `background-image: ${usernameColor(get(feed, 'entity.author'))} !important`
          "
          v-if="get(feed, 'entity.author.username')"
          :to="{
            name: 'profile',
            params: { username: get(feed, 'entity.author.username') },
          }"
          >@{{ truncateText(get(feed, 'entity.author.username'), 15) }}
        </router-link>
      </div>

      <hr v-if="!(get(feed, 'extra.fanart_url') || get(feed, 'extra.fanwork_image'))" />

      <div>
        <strong style="font-size: 17px">
          {{ get(feed, 'extra.fanwork_title') }}
        </strong>
        <div style="font-size: 15px" v-html="truncate(get(feed, 'extra.fanwork_description'), { length: 250 })" />
      </div>

      <div class="mt-2 d-flex align-items-center justify-content-center">
        <reaction
          type="visual"
          v-if="get(feed, 'extra.fanart_url') || get(feed, 'extra.fanwork_image')"
          :reactions="imageObj?.reaction_counts"
          :user-reaction="imageObj?.user_reaction"
          :index="1"
          :showBox="get(feed, 'entity.showBox')"
          :progressId="get(feed, 'entity.progressId')"
          @updated="emits('updated')"
          @delete="deleteCurrGiftBox"
          :left-indent-popup="0 % 2 === 0"
          :right-indent-popup="0 % 2 === 1"
          @changed="(reaction) => reactionChanged(reaction.key, imageObj)"
          @click.stop
        >
          <ion-button color="transparent" class="inline-button icon-button" @click.prevent>
            <div
              class="d-flex align-items-center justify-content-center text-primary"
              :class="{
                'text-secondary': !!imageObj?.user_reaction,
              }"
            >
              <i class="ti-thumb-up mr-1" />
              <span class="reaction-count">{{ imageObj?.reaction_counts?.total_count || 0 }}</span>
            </div>
          </ion-button>
        </reaction>

        <div class="d-flex align-items-center justify-content-center ml-auto" @click.stop>
          <!-- v-if="get(feed, 'extra.fanart_user')" -->
          <router-link
            :class="{
              'text-color-username':
                usernameColor(['fanart_submitted'].includes(feed.action) ? get(feed, 'user') : get(feed, 'extra')) &&
                !usernameColor(
                  ['fanart_submitted'].includes(feed.action) ? get(feed, 'user') : get(feed, 'extra')
                ).startsWith('#'),
              'bold-username': usernameColor(
                ['fanart_submitted'].includes(feed.action) ? get(feed, 'user') : get(feed, 'extra')
              ),
            }"
            :style="
              !usernameColor(['fanart_submitted'].includes(feed.action) ? get(feed, 'user') : get(feed, 'extra'))
                ? ''
                : usernameColor(
                    ['fanart_submitted'].includes(feed.action) ? get(feed, 'user') : get(feed, 'extra')
                  ).startsWith('#')
                ? `color: ${usernameColor(
                    ['fanart_submitted'].includes(feed.action) ? get(feed, 'user') : get(feed, 'extra')
                  )}`
                : `background-image: ${usernameColor(
                    ['fanart_submitted'].includes(feed.action) ? get(feed, 'user') : get(feed, 'extra')
                  )}`
            "
            :to="{
              name: 'profile',
              params: {
                username: ['fanart_submitted'].includes(feed.action)
                  ? get(feed, 'user.username')
                  : get(feed, 'extra.fanart_user'),
              },
            }"
            >@{{
              truncateText(
                ['fanart_submitted'].includes(feed.action)
                  ? get(feed, 'user.username')
                  : get(feed, 'extra.fanart_user'),
                15
              )
            }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getImage } from '@/shared/actions/imagesCollections';
import { resizeUpload } from '@/shared/utils/upload';
import Reaction from '@/shared/components/Reaction/index.vue';
import { reactImage } from '@/shared/pinia-store/shared/image';
import { truncateText } from '@/shared/utils/string';

const props = defineProps({
  entity: {
    type: Object,
    default: () => ({}),
  },
  feed: {
    type: Object,
    default: () => ({}),
  },
  containerHeight: {
    type: Number,
    default: 0,
  },
});
const containerHeight = toRef(props, 'containerHeight');
const feed = toRef(props, 'feed');
const imageObj: any = ref({});
const emits = defineEmits(['updated', 'delete']);
const fetchImage = async (id: any) => {
  const resp = await getImage(id);

  imageObj.value = resp;
};
const deleteCurrGiftBox = () => {
  emits('delete', get(feed.value, 'id'));
};

const usernameColor = (user: any) => {
  return user?.customize_profile?.username_color || '';
};

watch(
  feed,
  () => {
    if (!isEmpty(get(feed.value, 'extra.fanwork_image_id'))) {
      fetchImage(feed.value.extra.fanwork_image_id);
    }
  },
  { immediate: true }
);

const fanArtImgSize = computed(() => {
  return containerHeight.value ? { height: `${containerHeight.value}px` } : { height: 'auto' };
});

const reactionChanged = async (reaction: string, image: any) => {
  const resp = await reactImage({ reaction, image, fromRoulette: false });

  imageObj.value = {
    ...imageObj.value,
    ...resp.updatedReactionsData,
  };
};
</script>

<style lang="sass" scoped>
.text-secondary
  *
    color: #3dc2ff !important
.a-wrap
  width: 100%

.username
  width: calc(100% - 75px)
  a
    color: #FFDE67
    font-size: 12px
    font-weight: bold
    text-overflow: ellipsis
    width: 100%
    display: -webkit-box
    -webkit-line-clamp: 1
    -webkit-box-orient: vertical
    overflow: hidden
    text-align: end
.tag
  min-width: 90px
  padding-left: 10px
  padding-right: 10px
  height: 20px
  border: 1.5px solid #7AD7FF
  color: #7AD7FF
  background: #0A0928E5
  font-size: 10px
  font-weight: bold
  border-radius: 10px
.icon
  font-size: 16px
.actions
  max-width: 200px
  .primary-color
    color: #FFF
  .mr-2
    margin-right: 5px !important
  .ml-2
    margin-left: 5px !important
  *
    font-size: 12px !important
    color: #FFF
  .inline-button
    --ion-color-base: transparent !important
    &:not(:first-of-type)
      margin: 0 5px

.fanart-card
  border-radius: 12px
  background: #FFF
  padding: 12px
  .image
    background: #F178B9
    width: 100%
    height: 100%
    border-radius: 12px
    img
      width: 100%
      height: 100%
      border-radius: 12px
      object-fit: cover
  .name
    font-size: 14px
    font-weight: bold
    a
      color: #FFF
      // white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      width: 100%
  .description
    font-size: 13px
  .icon-button
    color: #ae38e5
    font-size: 20px
    cursor: pointer
    .d-flex
      font-size: 12px
  .icon-button:hover
    opacity: 0.7
  .reaction-count
    font-size: 15px
    font-weight: bold
.primary-color
  color: #7f10b3
.secondary-color
  color: #3dc2ff
.routerlink
  color: #214163

.img-char
  min-width: 25px
  width: 25px !important
  height: 25px !important
  border-radius: 30px
  border: solid gray 0.1px
  object-fit: cover

.feature-card
  border: 1px solid lightgrey
  border-radius: 12px
  padding: 10px
</style>
