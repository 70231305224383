<template>
  <ion-segment
    mode="md"
    class="tabs compact no-shadow tabs-segment d-flex"
    :value="activeIndex"
    @ion-change="segmentChanged($event)"
  >
    <div v-for="(tab, index) of tabs" :key="index" class="new-badge d-flex justify-content-center">
      <div v-if="tab.value === 'quizzes' && quizNotificationCount > 0" class="quiz-notifs no-select">
        <ion-badge class="no-select">{{ quizNotificationCount }} new</ion-badge>
      </div>
      <ion-segment-button
        :value="index"
        class="mr-1"
        :class="{ 'shade-new': activeIndex === eventsIndex && tab.value === 'events' && disabledForNewUser }"
      >
        <div class="d-flex align-items-center">
          <div class="label" :class="{ 'mr-2': !isMobileSmallScreen }">
            <ion-label class="quest-name no-select"
              >{{ tab.name }}
              <template v-if="isMobileSmallScreen && get(tabCounts, tab.value, 0)"
                >({{ get(tabCounts, tab.value, 0) }})</template
              >
            </ion-label>
          </div>
          <div v-if="!!get(tabCounts, tab.value, 0) && !isMobileSmallScreen" class="avv no-select">
            {{ get(tabCounts, tab.value, 0) }} available
          </div>
        </div>
      </ion-segment-button>
      <ion-badge v-if="newCounts[tab.value] > 0" color="danger"
        >{{ newCounts[tab.value] >= 99 ? '99+' : newCounts[tab.value] }} new</ion-badge
      >
    </div>
  </ion-segment>
</template>

<script lang="ts" setup>
import { Tab } from '@/shared/types/static-types';
import { onboardingStore } from '@/shared/pinia-store/onboarding';

const { disabledForNewUser } = onboardingStore();

const props = defineProps({
  tabs: {
    type: Array<Tab>,
    default: [] as Tab[],
  },
  activeIndex: {
    type: Number,
    default: 0,
  },
  totalQuests: {
    type: Number,
    default: 0,
  },
  totalRaffles: {
    type: Number,
    default: 0,
  },
  newCounts: {
    type: Object,
    default: () => {},
  },
  quizzesCount: {
    type: Number,
    default: 0,
  },
  quizNotificationCount: {
    type: Number,
    default: 0,
  },
});

const prevTab = ref('events');

const tabs = toRef(props, 'tabs');
const newCounts = toRef(props, 'newCounts');
const totalQuests = toRef(props, 'totalQuests');
const totalRaffles = toRef(props, 'totalRaffles');
const activeIndex = toRef(props, 'activeIndex');
const quizzesCount = toRef(props, 'quizzesCount');

const eventsIndex = computed(() =>
  indexOf(
    tabs.value,
    find(tabs.value, (tab) => tab.value === 'events')
  )
);

const emits = defineEmits(['update:activeIndex', 'tab-changed', 'newFlag']);

const segmentChanged = ($event: CustomEvent) => {
  const tabIndex = $event.detail.value as number;
  if (tabIndex !== undefined) {
    const tab = tabs.value[tabIndex];

    if (tab.value !== prevTab.value) {
      if (tab.value === 'raffles') {
        newCounts.value.events = 0;
        emits('newFlag', { events: false });
      } else {
        newCounts.value.raffles = 0;
        emits('newFlag', { raffles: false });
      }
    }
    prevTab.value = tab.value;

    emits('update:activeIndex', tabIndex);
    emits('tab-changed', tab);
  }
};
const isMobileSmallScreen = computed(() => {
  const { width } = useWindowSize();
  return width.value <= 550;
});

const tabCounts = computed(() => ({
  events: totalQuests.value,
  raffles: totalRaffles.value,
  quizzes: quizzesCount.value,
}));
</script>

<style scoped lang="sass">
.dark .new-badge .segment-button-checked
  background: #17074C !important
  box-shadow: 0px 1px 5px 1px rgba(189, 189, 189, 0.5019607843) !important

.shade-new
  background: rgba(209, 171, 245, 0.8) !important
  pointer-events: none
  border-bottom: 0 !important
.dark .shade-new
  background: rgba(209, 171, 245, 0.8) !important
.quiz-notifs
  position: absolute
  display: flex
  align-items: center
  justify-content: center
.label
  margin-top: -2px
  ion-label
    font-weight: 400 !important
    color: rgba(103, 28, 164, 1) !important
.segment-button-checked
  --background-checked: white
  --indicator-color: transparent !important
  border-bottom: 0px !important
  box-shadow:1px 1px 1px 1px #bdbdbd80
  background-color: var(--background-checked) !important
  .label
    margin-top: -2px
    ion-label
      font-weight: bold !important

.avv
  font-size: 10px !important
  color: rgba(103, 28, 164, 1) !important
.quest-name
  :deep
    user-select: none !important
  @media(min-width: 551px)
    font-size: 18px

ion-segment
  margin-bottom: -1px

.tabs
  //margin-top: -10px
  height: 42px !important
  padding: 9px
  background: transparent
  *
    font-size: 14px

ion-segment-button
  min-width: unset !important
  --indicator-color: transparent
  --background-checked: white
  border-radius: 7px
  background: transparent
  --background: transparent
  //border-bottom-left-radius:0 !important
  //border-bottom-right-radius:0 !important
  min-height: 29px !important
  max-height: 29px !important
  height: 29px !important
  //color: #5d5c5c !important
  width: inherit

  //border: 1.5px solid rgba(103, 28, 164, 1) !important
  @media(max-width:900px)
    margin-right: 0px
    margin-left: 0px

  @media(max-width:390px)
    --padding-start: 5px
    --padding-end: 5px
    *
      font-size: 12px !important

.new-badge
  position: relative
  width: 100%
  ion-badge
    position: absolute
    z-index: 1
    top: -10px
    color: white
    border-radius: 10px
    padding: 4px
    background: #7050B7 !important
    font-size: 10px
</style>
